import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import mission from "../images/mission-img.jpg"
// import Service from "../components/services"
// import Customers from "../components/customers"
// import Clients from "../components/clients"
import {Link} from "gatsby"

function AboutPage() {
  return (
    <Layout>
      <SEO title={"About"}/>

      <div id="aboutWrapper">
        {/*about header start here*/}
        <div className="about-wrapper">
          <div className="container  wrap-about text-center">
            <h1 className="text-light text-capitalize display-4">
              fine enterprises
            </h1>
            <p className="text-light">Scroll to know about us</p>
            <a href="#aboutInfo" className="icon-down text-light fab fa-angle-double-down"></a>
          </div>
        </div>
      </div>

      <div id="aboutInfo" className="container">
        <p className="text-center about-text">
          Fine Enterprises aspires to make lives of businesses and technicians easier by introducing state of the art
          range of products. Through a robust and efficient purchasing and distribution of a wider range of tools,
          machineries and accessories it leads in the market for both quality and value for money products.
          A progressive experience of over 10 years in importing and distributing of tools, machineries
          and accessories with a fully-fledged warehouse facility and fully computerized order processing
          system we ensure faster delivery covering customers all over the country. Fine Enterprises is one among
          few companies to get updates and technical know-how from the suppliers on innovative and state of the art
          products. These products match both industry standards and international quality specifications. Fine Enterprises
          deals with a wider variety of brands in the categories of agriculture machineries, hand tools, power tools and
          advanced equipment used for carpentry, masonry and automobile repair. It also deals with consumables
          and accessories related to these tools.
        </p>
      </div>

      <div className="fine-headline text-uppercase mt-0">
        <p className=''>
          Find what is
        </p>
        <h2 className="mb-2">
          Our Vision
        </h2>
      </div>
      <div className="container mb-4">
        <p className="text-center vision-text">Powerful and state-of-the-art tools for everyone  </p>
      </div>

      {/*<h1 className="text-center core-value-tittle">Core values</h1>*/}
      <div className="core-value bg-text-light pt-5 pb-5 mb-5 text-center text-capitalize">
        <div className="container">
          <div className="col-12 row m-0">
            <div className="col-md-3 d-flex align-items-center flex-column mb-3 mt-3">
              <span className="fab fa-handshake-o hands"></span>
              <p>Integrity</p>
            </div>
            <div className="col-md-3 d-flex align-items-center flex-column mb-3 mt-3">
              <span className="fab fa-lightbulb-o bulb"></span>
              <p>Innovation</p>
            </div>
            <div className="col-md-3 d-flex align-items-center flex-column mb-3 mt-3">
              <span className="fab fa-tachometer speed"></span>
              <p>Efficiency</p>
            </div>
            <div className="col-md-3 d-flex align-items-center flex-column mb-3 mt-3">
              <span className="fab fa-smile-o smile"></span>
              <p>Customer Satisfaction</p>
            </div>
          </div>
        </div>
      </div>

      <div className="fine-headline text-uppercase ">
        <p className=''>
          Find what is
        </p>
        <h2 className="mb-4">
          Our Mission
        </h2>
      </div>

      <div className="mission container">
        <div className="row col-12 justify-content-center align-items-center">
          {/*<div className="col-md-6 col-sm-12 mission-img text-center">*/}
          {/*  <img src={mission} alt="mission image" height="100%"/>*/}
          {/*</div>*/}
          <div className="col-md-12 col-sm-12 p-4 mission-p">
            <p>To thrive in the market by fulfilling the needs and wants of our valued customers, thus we</p>
            <ul>
              <li>Ensure efficient and timely distribution of products to make them available to any parts of the country. </li>
              <li>Exceed customer expectations through excellent and prompt customer service to both internal and external customers.</li>
              <li>Contribute to the nation by facilitating rapid developing sectors and industries with right tools and machineries. </li>
              <li>Develop ourselves to claim No.1 for power tools by ensuring ultimate range tools, accessories and after sales service.  </li>
            </ul>
          </div>
        </div>
      </div>


      <div className="fine-headline text-uppercase ">
        <h2 className="mb-4">
          Our Enterprises
        </h2>
      </div>

      <div className="exp container text-center text-md-left">
        <p>
          Fine Enterprises has a progressive experience of over 10 years in importing and distributing tools, machineries and accessories.  A fully-fledged warehouse facility with computer-based order processing system that makes sure faster delivery of items to customers all over the country. Fine Enterprises is one among few companies to get updates and technical know-how from the suppliers on innovative and state of the art products. These products match both industry standards and international quality specifications.
          <br/>
          Fine Enterprises deals with a wider variety of products in the categories of agriculture machineries, hand tools, power tools and advanced equipment used for carpentry, masonry and automobile repair. It also deals with consumables and accessories related to these tools.
        </p>
      </div>

      <Link to="/contact" className="about-contact">Contact Us</Link>
    </Layout>
  )
}

export default AboutPage
